<template>
  <div class="power-bi-list">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Cài đặt power bi'">
      <template v-slot:preview>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label class="font-weight-bolder"
                >Token
                <span
                  v-if="tokenConfig.id"
                  class="font-weight-bolder text-primary mr-2"
                  >#{{ tokenConfig.id }}</span
                >
                <b-badge
                  variant="success"
                  v-if="statusToken === TOKEN_STATUS.VALID"
                  >Hoạt động</b-badge
                >
                <b-badge
                  variant="danger"
                  v-if="statusToken === TOKEN_STATUS.EXPRIRED"
                  >Đã hết hạn</b-badge
                >
              </label>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col cols="9" class="pr-2">
                      <b-form-textarea
                        placeholder="Mã token"
                        v-model="tokenConfig.accessToken"
                        autocomplete="off"
                        size="sm"
                        rows="8"
                        max-rows="8"
                        :disabled="true"
                      ></b-form-textarea>
                    </b-col>
                    <b-col cols="3" class="pl-2">
                      <b-overlay
                        :show="isBusy"
                        rounded
                        opacity="0.6"
                        class="mb-2"
                      >
                        <template #overlay>
                          <div class="d-flex align-items-center">
                            <b-spinner
                              variant="light"
                              small
                              type="grow"
                            ></b-spinner>
                          </div>
                        </template>
                        <b-button
                          block
                          variant="warning"
                          size="sm"
                          @click="onRefeshToken"
                          class="font-weight-bolder"
                        >
                          Làm mới token
                        </b-button>
                      </b-overlay>

                      <b-overlay
                        :show="isBusy"
                        rounded
                        opacity="0.6"
                        class="mb-2"
                      >
                        <template #overlay>
                          <div class="d-flex align-items-center">
                            <b-spinner
                              variant="light"
                              small
                              type="grow"
                            ></b-spinner>
                          </div>
                        </template>
                        <b-button
                          block
                          variant="primary"
                          size="sm"
                          @click="onGetNewToken"
                          class="font-weight-bolder"
                        >
                          Lấy token mới
                        </b-button>
                      </b-overlay>

                      <!-- <b-button
                        block
                        variant="secondary"
                        size="sm"
                        class="font-weight-bolder d-block ml-1"
                      >
                        Làm mới báo cáo
                      </b-button> -->
                      <b-button
                        block
                        variant="secondary"
                        size="sm"
                        v-clipboard:copy="tokenConfig.accessToken"
                      >
                        <i class="fa fa-clipboard" aria-hidden="true"></i>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row>
                <b-col cols="6">
                  <label class="font-weight-bolder">Tài khoản đăng nhập</label>
                  <b-form-input
                    placeholder="Mã token"
                    size="sm"
                    autocomplete="off"
                    v-model="tokenConfig.userId"
                    :disabled="true"
                  />
                </b-col>

                <b-col cols="6">
                  <label class="font-weight-bolder">clientId </label>
                  <b-form-input
                    placeholder="workspaceId"
                    size="sm"
                    autocomplete="off"
                    v-model="tokenConfig.clientId"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row>
                <b-col cols="6">
                  <label class="font-weight-bolder">Thời gian tạo token </label>
                  <b-form-input
                    placeholder="Mã token"
                    size="sm"
                    autocomplete="off"
                    v-model="tokenConfig.createdAt"
                    :disabled="true"
                  />
                </b-col>
                <b-col cols="6">
                  <label class="font-weight-bolder">workspaceId</label>
                  <b-form-input
                    placeholder="workspaceId"
                    size="sm"
                    autocomplete="off"
                    v-model="tokenConfig.workspaceId"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row>
                <b-col cols="6">
                  <label class="font-weight-bolder"
                    >Thời gian hết hạn token
                  </label>
                  <b-form-input
                    placeholder="Mã token"
                    size="sm"
                    autocomplete="off"
                    v-model="tokenConfig.expiresOn"
                    :disabled="true"
                  />
                </b-col>
                <b-col cols="6">
                  <label class="font-weight-bolder">apiUrl</label>
                  <b-form-input
                    placeholder="apiUrl"
                    size="sm"
                    autocomplete="off"
                    v-model="tokenConfig.apiUrl"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group>
              <b-row>
                <b-col cols="6">
                  <label class="font-weight-bolder"
                    >Thời gian (giây) hết hạn Token</label
                  >
                  <b-form-input
                    placeholder="Thời gian (giây) hết hạn Token"
                    size="sm"
                    autocomplete="off"
                    v-model="timerCount"
                    :disabled="true"
                  />
                </b-col>
                <b-col cols="6">
                  <label class="font-weight-bolder"
                    >Thời gian (giây) hết hạn Token</label
                  >
                  <b-form-input
                    placeholder="Tổng (giây) hết hạn token"
                    size="sm"
                    autocomplete="off"
                    v-model="tokenConfig.expiresIn"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label class="font-weight-bolder">Danh sách token</label>
              <b-row>
                <b-col cols="12">
                  <b-table
                    :items="tokens"
                    :fields="tokensFields"
                    :small="true"
                    :busy="onLoadingList"
                    :per-page="perPageTokens"
                    :current-page="currentPageTokens"
                    responsive
                    bordered
                    hover
                  >
                    <template v-slot:table-busy>
                      <vcl-table
                        v-if="onLoadingList"
                        :speed="5"
                        :animate="true"
                        :columns="6"
                      ></vcl-table>
                    </template>
                    <template #cell(actions)="data">
                      <b-dropdown size="sm" no-caret right lazy>
                        <template slot="button-content">
                          <i
                            style="font-size: 1rem"
                            class="flaticon2-settings pr-0"
                          ></i>
                        </template>

                        <b-dropdown-item @click="popupUserReport(data.item)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="fa fa-eye"
                              aria-hidden="true"
                            ></i>
                            &nbsp; Chỉnh sửa
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="onRefeshReportToken(data.item)"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="fa fa-eye"
                              aria-hidden="true"
                            ></i>
                            &nbsp; Lấy token
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="3" sm="12">
                  <b>Tổng số: {{ tokens.length }} </b>
                </b-col>
                <b-col lg="9" md="9" sm="12">
                  <b-pagination
                    pills
                    v-if="tokens.length > perPageTokens"
                    v-model="currentPageTokens"
                    :total-rows="tokens.length"
                    :per-page="perPageTokens"
                    aria-controls="my-table"
                    align="right"
                    size="sm"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <KTCodePreview v-bind:title="'Cài đặt báo cáo'">
      <template v-slot:preview>
        <b-row class="mb-4">
          <b-col cols="3">
            <b-input
              placeholder="Nhập tên hoặc mã báo cáo"
              v-model="apiParams.reportInfor"
              size="sm"
              autocomplete="off"
            ></b-input>
          </b-col>
          <b-col>
            <b-button
              size="sm"
              style="width: 80px"
              class="font-weight-bolder mr-2"
              variant="primary"
              @click="getReports(false)"
              ><i class="fas fa-search text-white fa-lg"></i>Lọc</b-button
            >
            <b-button
              size="sm"
              style="width: 120px"
              class="font-weight-bolder"
              variant="warning"
              @click="popupAddReports"
              ><i class="fas fa-plus text-white fa-lg"></i>Thêm báo
              cáo</b-button
            >
          </b-col>
        </b-row>
        <PowerBiReportsTable
          :items="reports"
          :onLoadingList="onLoadingList"
          v-on:popup-user-report="popupUserReport"
          v-on:onrefesh-report-token="onRefeshReportToken"
        />
        <EmployeeReport :report="reportSelected" />
        <b-modal
          id="md-add-reports"
          hide-footer
          title="Thêm báo cáo từ api"
          no-close-on-backdrop
          size="lg"
        >
          <b-row>
            <b-col cols="12">
              <b-row class="mb-4">
                <b-col cols="4">
                  <b-input
                    placeholder="Nhập tên hoặc mã báo cáo"
                    v-model="apiParams.reportInfor"
                    size="sm"
                    autocomplete="off"
                  ></b-input>
                </b-col>
                <b-col cols="4">
                  <b-input
                    placeholder="Mặc định groupId"
                    v-model="apiParams.groupId"
                    size="sm"
                    autocomplete="off"
                  ></b-input>
                </b-col>
                <b-col>
                  <b-button
                    size="sm"
                    style="width: 120px"
                    class="font-weight-bolder mr-2"
                    variant="primary"
                    @click="getReports(true)"
                    ><i class="fas fa-search text-white fa-lg"></i>Lấy báo
                    cáo</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <PowerBiReportsTable
                :items="reportsApi"
                :isApiPowerBi="isApiPowerBi"
                :onLoadingList="onLoadingList"
                v-on:on-selected-report-api="onSelectedReportApi"
              />
            </b-col>
          </b-row>
          <b-overlay
            v-if="reportsApi.length"
            :show="isBusy"
            rounded
            opacity="0.6"
            class="d-inline-block mr-3"
          >
            <template #overlay>
              <div class="d-flex align-items-center">
                <b-spinner variant="light" small type="grow"></b-spinner>
              </div>
            </template>
            <b-button
              @click="updateReportsApi"
              :disabled="isBusy"
              variant="primary"
              size="sm"
            >
              <strong>Thêm báo cáo</strong>
            </b-button>
          </b-overlay>

          <b-button
            style="width: 80px"
            variant="secondary"
            size="sm"
            @click="$bvModal.hide('md-add-reports')"
          >
            <strong>Hủy</strong>
          </b-button>
        </b-modal>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import EmployeeReport from './modal/EmployeeReport';
import { VclTable } from 'vue-content-loading';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';
import PowerBiReportsTable from '@/view/components/power-bi/PowerBiReportsTable';

const TOKEN_STATUS = {
  VALID: 1,
  EXPRIRED: 2,
};
export default {
  data() {
    return {
      perPageReports: 10,
      perPageTokens: 5,
      currentPageReports: 1,
      currentPageTokens: 1,
      tokenConfig: {
        id: null,
        accessToken: null,
      },
      loaderEnabled: false,
      reports: [],
      reportSelected: null,
      tokensFields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'text-center',
          tdClass: 'text-center align-middle',
          thStyle: {
            width: '2%',
          },
        },
        {
          key: 'countRefresh',
          label: 'Làm mới (Lần)',
          thClass: 'text-center',
          tdClass: 'text-center align-middle',
          thStyle: {
            width: '5%',
          },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thClass: 'text-center',
          tdClass: 'align-middle',
          thStyle: {
            width: '5%',
          },
        },
        {
          key: 'expiresOn',
          label: 'Ngày hết hạn',
          thClass: 'text-center',
          tdClass: 'align-middle',
          thStyle: {
            width: '5%',
          },
        },
        {
          key: 'actions',
          label: ' ',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      timerCount: 0,
      statusToken: null,
      TOKEN_STATUS,
      tokens: [],
      isBusy: false,
      onLoadingList: false,
      apiParams: {
        reportInfor: null,
        groupId: '5405dc29-8a23-42f3-95af-2f401cf33588',
      },
      reportsApi: [],
      isApiPowerBi: false,
      selectedReportsApi: [],
    };
  },
  components: {
    KTCodePreview,
    EmployeeReport,
    VclTable,
    PowerBiReportsTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cài đặt power bi', route: 'power-bi' },
    ]);
  },
  created() {
    this.onFilter();
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value <= 0) {
          this.statusToken = TOKEN_STATUS.EXPRIRED;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    makeToastSuccess,
    onFilter() {
      this.getReports();
      this.getTokenList();
      this.getTokenSetting();
    },
    popupAddReports() {
      this.isApiPowerBi = true;
      this.selectedReportsApi = []
      this.$bvModal.show('md-add-reports');
    },
    async onGetNewToken() {
      if (!this.isBusy) {
        this.isBusy = true;
        await ApiService.get('power-bi/token/create-new');
        this.isBusy = false;
        this.onFilter();
      }
    },
    async onRefeshToken() {
      if (!this.isBusy) {
        this.isBusy = true;
        const tokenId = this.tokenConfig.id;
        const params = {
          tokenId,
        };
        await ApiService.query('power-bi/token/refresh', {
          params,
        });
        this.isBusy = false;
        this.onFilter();
      }
    },
    onRefeshReportToken(report) {
      const params = {
        id: report.id,
      };
      ApiService.query('power-bi/report/token-renew', { params }).then(() => {
        this.getReports();
      });
    },
    getTokenSetting() {
      ApiService.get('power-bi/token/infor').then((reponse) => {
        const { data } = reponse.data;
        this.tokenConfig = data;
        const expiresOnSecond = data.expiresOnSecond;
        const currentDateSecond = data.currentDateSecond;
        const sub = expiresOnSecond - currentDateSecond;
        if (sub > 0) {
          this.statusToken = TOKEN_STATUS.VALID;
          this.timerCount = Math.floor(sub / 1000);
          this.countDownTimer();
        } else {
          this.statusToken = TOKEN_STATUS.EXPRIRED;
        }
      });
    },
    getTokenList() {
      ApiService.get('power-bi/token/').then((reponse) => {
        const { data } = reponse.data;
        this.tokens = data;
      });
    },
    getReports(isApiPowerBi = false) {
      if (!this.onLoadingList) {
        this.onLoadingList = true;

        const params = {
          ...this.apiParams,
          isApiPowerBi: isApiPowerBi || false,
        };
        ApiService.query('power-bi/report/', {
          params,
        })
          .then((reponse) => {
            const { data } = reponse.data;
            if (isApiPowerBi) {
              this.reportsApi = data;
            } else {
              this.reports = data;
            }
            this.onLoadingList = false;
          })
          .catch(() => {
            this.onLoadingList = false;
          });
      }
    },
    popupUserReport(item) {
      this.reportSelected = item;
      this.$bvModal.show('md-setting-user');
    },
    countDownTimer() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          this.timerCount -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onSelectedReportApi(item) {
      const selectedItems = this.selectedReportsApi;
      const index = selectedItems.findIndex((prop) => prop.id === item.id);
      if (index < 0) {
        this.selectedReportsApi.push(item);
      } else {
        if (!item.selected) {
          this.selectedReportsApi.splice(index, 1);
        }
      }
    },
    vadidateUpdateReportsApi(reportsUpdate) {
      const reports = this.reports;
      const duplicate = [];
      for (const reportUpdate of reportsUpdate) {
        const reportFinded = reports.find((item) => {
          let check = false;
          if (item.reportId === reportUpdate.id) {
            check = true;
          }
          if (item.name === reportUpdate.name) {
            check = true;
          }
          if (check) {
            return item;
          }
        });
        if (reportFinded) {
          duplicate.push(reportUpdate.name);
        }
      }
      if (duplicate.length) {
        makeToastFaile(duplicate.join() + ' đã có trong danh sách');
        return false;
      }
      return true;
    },
    updateReportsApi() {
      const data = [...this.selectedReportsApi];

      const isValid = this.vadidateUpdateReportsApi(data);
      if (!isValid) {
        return;
      }
      if (!this.isBusy) {
        this.isBusy = true;

        ApiService.post('power-bi/report/bulk', data)
          .then(() => {
            this.$bvModal.hide('md-add-reports');
            this.reportsApi = this.reportsApi.map((item) => {
              item.selected = false;
              return item;
            });
            this.getReports(false);
            this.isBusy = false;
          })
          .catch(() => {
            this.isBusy = false;
          });
      }
    },
  },
};
</script>
<style lang="scss">
.power-bi-list {
  .wrapbox {
    width: 20em;
    padding: 0.5em;
    white-space: normal;
    vertical-align: top;
    display: inline-block;
  }
  .auto {
    line-break: auto;
  }
  table {
    table-layout: fixed;
    word-wrap: break-word;
  }
}
</style>
