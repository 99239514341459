var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-common"},[_c('b-modal',{attrs:{"id":"md-setting-user","hide-footer":"","title":"Cấu hình nhân viên:","no-close-on-backdrop":"","size":"lg"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"4"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.optionsEmployee,"limit":100,"input-props":{
            class: 'autosuggest__input',
            placeholder: 'Nhập nhân viên CSKH',
          },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; },"get-suggestion-value":function (ref) {
                    var item = ref.item;

                    return item.fullName;
}},on:{"selected":function($event){return _vm.onSelected($event, 'Employee')},"input":function($event){return _vm.onInputed($event, 'Employee')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var suggestion = ref.suggestion;
return [_vm._v(" "+_vm._s(suggestion.item.fullName)+" ")]}}]),model:{value:(_vm.apiParams.employeeName),callback:function ($$v) {_vm.$set(_vm.apiParams, "employeeName", $$v)},expression:"apiParams.employeeName"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-table',{attrs:{"items":_vm.employees,"fields":_vm.employeesFields,"small":true,"responsive":"","bordered":"","hover":""},scopedSlots:_vm._u([{key:"cell(code)",fn:function(data){return [_c('b',{staticClass:"text-primary"},[_vm._v(_vm._s(data.item.code))])]}},{key:"cell(actions)",fn:function(data){return [(data.item.reportDeleted === 1)?_c('div',[_c('div',{on:{"click":function($event){return _vm.removeEmployess(data.item.id, 0)}}},[_c('span',[_c('i',{staticClass:"fa fa-recycle text-warning",attrs:{"aria-hidden":"true"}}),_vm._v(" Hoàn tác ")])])]):_c('div',[_c('div',{on:{"click":function($event){return _vm.removeEmployess(data.item.id, 1)}}},[_c('span',[_c('i',{staticClass:"fa fa-trash text-danger",staticStyle:{"font-size":"1rem"},attrs:{"aria-hidden":"true"}}),_vm._v(" Xoá ")])])])]}}])})],1)],1),_c('b-overlay',{staticClass:"d-inline-block mr-3",attrs:{"show":_vm.isBusy,"rounded":"","opacity":"0.6"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{attrs:{"variant":"light","small":"","type":"grow"}})],1)]},proxy:true}])},[_c('b-button',{attrs:{"disabled":_vm.isBusy,"variant":"primary","size":"sm"},on:{"click":_vm.onSaveUserReport}},[_c('strong',[_vm._v("Lưu")])])],1),_c('b-button',{staticStyle:{"width":"80px"},attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.hide('md-setting-user')}}},[_c('strong',[_vm._v("Hủy")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }