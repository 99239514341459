<template>
  <div>
    <b-table
      :items="reports"
      :fields="reportsFields"
      :small="true"
      :busy="onLoadingList"
      :per-page="perPageReports"
      :current-page="currentPageReports"
      responsive
      bordered
      hover
    >
      <template v-slot:table-busy>
        <vcl-table
          v-if="onLoadingList"
          :speed="5"
          :animate="true"
          :columns="6"
        ></vcl-table>
      </template>
      <template #cell(selected)="data" v-if="isApiPowerBi">
        <input
          type="checkbox"
          @change="onSelectedItem(data.item)"
          v-model="data.item.selected"
        />
      </template>
      <template #cell(name)="data">
        <b class="text-primary">{{ data.item.name }}</b>
      </template>
      <template #cell(embedUrl)="data">
        <div v-if="data.item.embedUrl">
          {{
            data.item.embedUrl
              ? data.item.embedUrl.substring(0, 90) + '...'
              : ''
          }}
          <i
            style="font-size: 1rem"
            class="fa fa-eye"
            aria-hidden="true"
            v-b-tooltip
            :title="data.item.embedUrl"
          ></i>
        </div>
      </template>

      <template #cell(statusToken)="data">
        <b-badge
          :variant="data.item ? (data.item.token ? 'success' : 'danger') : ''"
          v-text="htmlGetText(data.item, 'token')"
        ></b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown size="sm" no-caret right lazy>
          <template slot="button-content">
            <i style="font-size: 1rem" class="flaticon2-settings pr-0"></i>
          </template>

          <b-dropdown-item @click="emitPopupUserReport(data.item)">
            <span style="color: #3f4254; font-size: 12px">
              <i
                style="font-size: 1rem"
                class="flaticon2-pen"
                aria-hidden="true"
              ></i>
              &nbsp; Chỉnh sửa
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="emitOnRefeshReportToken(data.item)">
            <span style="color: #3f4254; font-size: 12px">
              <i
                style="font-size: 1rem"
                class="flaticon2-refresh-arrow"
                aria-hidden="true"
              ></i>
              &nbsp; Lấy token
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="emitOnRefeshReportToken(data.item)">
            <span style="color: #3f4254; font-size: 12px">
              <i
                style="font-size: 1rem"
                class="fa fa-eye"
                aria-hidden="true"
              ></i>
              &nbsp; Xem trước
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-row>
      <b-col lg="3" md="3" sm="12">
        <b>Tổng số: {{ reports.length }} </b>
      </b-col>
      <b-col lg="9" md="9" sm="12">
        <b-pagination
          pills
          v-if="reports.length > perPageReports"
          v-model="currentPageReports"
          :total-rows="reports.length"
          :per-page="perPageReports"
          aria-controls="my-table"
          align="right"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VclTable } from 'vue-content-loading';
import { cloneDeep } from '@/utils/common';
export default {
  mixins: [],
  props: {
    items: Array,
    onLoadingList: Boolean,
    isApiPowerBi: Boolean,
  },
  components: {
    VclTable,
  },
  data() {
    return {
      perPageReports: 10,
      currentPageReports: 1,
      reportsFields: [],
      reports: [],
    };
  },
  watch: {
    items: {
      handler(value) {
        if (value) {
          this.reports = cloneDeep(this.items || []);
        }
      },
      deep: true,
    },
    isApiPowerBi: {
      handler(value) {
        this.reportsFields = this.getFieldTable(value);
      },
      deep: true,
    },
  },
  computed: {},
  created() {
    this.reports = cloneDeep(this.items);
    this.reportsFields = this.getFieldTable(this.isApiPowerBi);
  },
  methods: {
    getFieldTable(isApiPowerBi) {
      if (isApiPowerBi) {
        return [
          {
            key: 'selected',
            label: '',
            tdClass: 'text-center',
            thStyle: {
              width: '2%',
            },
          },
          {
            key: 'name',
            label: 'Tên báo cáo',
            thClass: 'text-center',
            tdClass: 'align-middle',
            thStyle: {
              width: '10%',
            },
          },
        ];
      } else {
        return [
          {
            key: 'createdAt',
            label: 'Ngày tạo',
            thClass: 'text-center',
            tdClass: 'align-middle',
            thStyle: {
              width: '5%',
            },
          },
          {
            key: 'name',
            label: 'Tên báo cáo',
            thClass: 'text-center',
            tdClass: 'align-middle',
            thStyle: {
              width: '10%',
            },
          },
          {
            key: 'embedUrl',
            label: 'Nhúng báo cáo',
            thClass: 'text-center',
            thStyle: {
              width: '20%',
            },
          },
          {
            key: 'statusToken',
            label: 'Trạng thái token',
            thClass: 'text-center',
            tdClass: 'text-center align-middle',
            thStyle: {
              width: '10%',
            },
          },
          {
            key: 'actions',
            label: ' ',
            tdClass: 'text-center align-middle',
            thStyle: {
              width: '5%',
            },
          },
        ];
      }
    },
    htmlGetText(item, prop) {
      let text = '';
      switch (prop) {
        case 'token': {
          text = item.token ? 'Hoạt động' : 'Chưa có token';
          break;
        }
      }
      return text;
    },
    htmlGetColor(item, prop) {
      let color = '';
      switch (prop) {
        case 'token': {
          color = item.token ? 'text-success' : 'text-danger';
          break;
        }
      }
      return color;
    },
    emitPopupUserReport(item) {
      this.$emit('popup-user-report', item);
    },
    emitOnRefeshReportToken(item) {
      this.$emit('onrefesh-report-token', item);
    },
    onSelectedItem(item) {
      this.$emit('on-selected-report-api', item);
    },
  },
};
</script>
